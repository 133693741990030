import { EIndicationStatus } from '~/graphql/types'

export const canDownloadProposal = (status: EIndicationStatus) => {
  if(status === EIndicationStatus.pending) {
    return false
  }

  if(status === EIndicationStatus.canceled) {
    return false
  }

  return true
}
